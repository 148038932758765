import {Injectable} from "@angular/core";
import {AccountRepository} from "../repository/account.repository";
import {Observable} from "rxjs";
import {Account} from "../models/account.model";
import {AccountFetchOptions} from "../models/account-fetch-options.model";

@Injectable({
  providedIn: "root"
})
export class AccountService {

  accounts$: Observable<Account[]>;

  constructor(private accountRepository: AccountRepository) {
    this.accounts$ = this.accountRepository.accounts$;
  }

  addZeroBefore(value): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
  formatDate(date): string {
    const year = date.getFullYear();
    const month = this.addZeroBefore(date.getMonth() + 1);
    const day = this.addZeroBefore(date.getDate());
    const hours = this.addZeroBefore(date.getHours());
    const minutes = this.addZeroBefore(date.getMinutes());
    const seconds = this.addZeroBefore(date.getSeconds());

    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  }

  getAccount(fetchOptions: AccountFetchOptions): Observable<Account> {
    return this.accountRepository.getAccount(fetchOptions);
  }

  /**
   * Makes the repository put new accounts in the accountsSource subject and returns total count
   * @param fetchOptions
   * @param resetPage
   */
  getAccounts(fetchOptions: AccountFetchOptions, resetPage: boolean): Observable<number> {
    return this.accountRepository.getAccounts(fetchOptions, resetPage);
  }

  createAccount(newAccount: Account, avatar?: File): Observable<any> {
    return this.accountRepository.createAccount(newAccount, avatar);
  }

  editAccount(account: Account, accountId: number, avatar?: File): Observable<any> {
    return this.accountRepository.editAccount(account, accountId, avatar);
  }

  checkIfEmailIsUnique(email: string) {
    return this.accountRepository.checkIfEmailIsUnique(email);
  }

  getStatistics(fetchOptions: AccountFetchOptions): Observable<Date[]> {
    return this.accountRepository.getStatistics(fetchOptions);
  }

  deleteAccount(accountId: number): Observable<any> {
    return this.accountRepository.deleteAccount(accountId);
  }

  getFile() {
    return this.accountRepository.getFile().subscribe(res => {
      const blob = new Blob([res], {type: 'text/csv'});
      const url = window.URL.createObjectURL(blob);

      // create hiddden element to automatically download file
      const a = document.createElement('a');
      a.href = url;
      a.download = `Accounts_${this.formatDate(new Date())}`;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  }

    changeCalibrationDate(accountId: number): Observable<any> {
        return this.accountRepository.changeCalibrationDate(accountId);
    }
}
